import { Menu, MenuItem, IconButton, Divider, Tooltip } from '@mui/material';
import classes from "./WalletMenu.module.scss";
import { formatIndianPayment } from '../../../../../utils/utils';
import { useContext, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useModal } from '../../../../../store/modalContext';
import { MintBox } from '../../../../owner/wallet/MintBox';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import BalanceContext, { useBalance } from '../../../../../store/balanceContext';
import { useModulePrivilage } from '../../../../../store/modulePrivilageContext';
import { OwnerModuleNames } from '../../../../../utils/enums';
// const StyledIconButton = styled(IconButton)(({ theme }) => ({
//   '&:hover': {
//     backgroundColor: 'transparent', // Override the hover background color
//   },
// }));

const WalletMenu = () => {
  const isOwner = process.env.REACT_APP_ADMIN_APP_NAME === 'owner';
  const { openModal, closeModal, modalId } = useModal();
  const [anchorEl, setAnchorEl] = useState(null);
  const { balance, exposure, creditBalance } = useBalance();
  const [balanceShowOnTop, setBalanceShowOnTop] = useState(false);
  const balanceCtx = useContext(BalanceContext);
  const { moduleList } = useModulePrivilage();
  const isMintModulePresent = moduleList.some(module => module.name === OwnerModuleNames.MINT);
  const handleMint = () => {
    const modalContent = <MintBox closeModal={closeModal} modalId={modalId} />
    openModal(modalContent, "Points to mint");
  }

  // useEffect(() => {
  //   balanceCtx?.fetchBalance();
  // }, []);

  useEffect(() => {
    if(balance>=creditBalance) {
      setBalanceShowOnTop(true);  
    }else {
      setBalanceShowOnTop(false);
    }  
  }, [balance, creditBalance]);

  const handleClick = (event: any) => {
    if (!isOwner) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className={classes.accountBalance_wrapper}>
        <label className={classes.headeing_label}>{(balance >= creditBalance) ? 'Balance' : 'Credit Balance'}</label>
        <div className={classes.account_balanceText} style={{minWidth: "120px"}}>
          <CurrencyRupeeIcon sx={{ verticalAlign: 'middle', fontSize: 'inherit' }} /> 
          {balanceShowOnTop ? formatIndianPayment(balance.toString()) : formatIndianPayment(creditBalance.toString())}
        </div>
        <div className={classes.buttonWrapper}>
          <Tooltip title="Refresh Balance" arrow>
            <IconButton
              aria-controls="wallet-menu"
              aria-haspopup="true" color="primary" className='icon_add' size="small" onClick={balanceCtx?.fetchBalance} classes={{ root: classes.iconButton }}>
                <RefreshIcon fontSize='medium' style={{ color: "#fff" }} />
            </IconButton>
            </Tooltip>
        </div>
        { isMintModulePresent &&
          <div className={classes.buttonWrapper} style={{marginLeft: "2px"}}>
            <Tooltip title="Mint" arrow>
              <IconButton
                aria-controls="wallet-menu"
                aria-haspopup="true" color="primary" className='icon_add' size="small" onClick={handleMint} classes={{ root: classes.iconButton }}>
                  <AddIcon fontSize='medium' style={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
          </div>
        }
        { process.env.REACT_APP_ADMIN_APP_NAME === 'whitelabel' &&
          <div className={classes.buttonWrapper} style={{marginLeft: "2px"}}>
            <Tooltip title="View more" arrow>
              <IconButton
                aria-controls="wallet-menu"
                aria-haspopup="true" color="primary" className='icon_add' size="small" onClick={handleClick} classes={{ root: classes.iconButton }}>
                  <KeyboardArrowDownIcon fontSize='medium' style={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
          </div>
        }
      </div>
      <Menu
        id="wallet-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {!balanceShowOnTop ?
          <MenuItem>
            <p className={classes.txtColor}>Balance:</p> 
            <p className={classes.amtfont}>
              <CurrencyRupeeIcon sx={{ verticalAlign: 'middle', fontSize: 'inherit' }} /> 
              {formatIndianPayment(balance.toString())}
            </p>
          </MenuItem> :
          <MenuItem>
            <p className={classes.txtColor}>Credit Balance:</p>
            <p className={classes.amtfont}>
              <CurrencyRupeeIcon sx={{ verticalAlign: 'middle', fontSize: 'inherit' }} /> 
              {formatIndianPayment(creditBalance.toString())}
            </p>
          </MenuItem>
        }
        <Divider />
        <MenuItem>
          <p className={classes.txtColor}>Exposure:</p> 
          <p className={classes.amtfont}>
            <CurrencyRupeeIcon sx={{ verticalAlign: 'middle', fontSize: 'inherit' }} /> 
            {formatIndianPayment(exposure.toString())}
          </p>
        </MenuItem>
        {/* <MenuItem><p className={classes.txtColor}>Downline Credit:</p> <p style={{ marginLeft: '5px' }}>200</p></MenuItem>
        <MenuItem><p className={classes.txtColor}>Downline P/L:</p> <p style={{ marginLeft: '5px' }}>500</p></MenuItem> */}
      </Menu>
    </div>
  );
};

export default WalletMenu;
