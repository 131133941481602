// config.js or ConfigService.js 

import { IConfig } from "../interfaces/configInterface";
import { OwnerModuleNames, OwnerRoutes, UserRole, WhitelabelModuleNames, WhitelabelRoutes, WhitelabelType } from "../utils/enums";
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import SearchIcon from '@mui/icons-material/Search';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LanRoundedIcon from '@mui/icons-material/LanRounded';
import DynamicFeedRoundedIcon from '@mui/icons-material/DynamicFeedRounded';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import PointOfSaleRoundedIcon from '@mui/icons-material/PointOfSaleRounded';
import PublishedWithChangesRoundedIcon from '@mui/icons-material/PublishedWithChangesRounded';

const config: IConfig = {
  roleBasedConfig: {
    owner: {
      apiEndpoint: {
        // API Constatnts for Owner
        getModulesApiURL: "/api/v1/module/get",
        createModuleApiURL: "/api/v1/module/create",
        assignModulesToRolesApiURL: "/api/v1/module/assign-modules",
        addOwnerApiURL: "/api/v1/owner/register",
        loginApiURL: "/api/v1/owner/login",
        getSubowners: "/api/v1/owner/get-sub-users",
        paymentCreate: "/api/v1/payment/create",
        getAllPayementMethod: "/api/v1/payment/get",
        assignPaymentMethod: "/api/v1/payment/assign-payment-method",
        getRolesApiURL: "/api/v1/roles/",
        createRoleApiURL: "/api/v1/roles/create",
        ownerMintWalletApiURL: "/api/v1/wallet/mint",
        creditWhiteLabelApiURL: "/api/v1/wallet/transfer/whitelabel",
        createWhiteLabelApiURL: "/api/v1/whitelabel/create",
        editWhiteLabelApiURL: "/api/v1/whitelabel/edit",
        getWhiteLabelListApiURL: "/api/v1/whitelabel/list",
        addConfigApiURL: "/api/v1/whitelabel/addconfig",
        getWhiteLabelByIdApiURL: "/api/v1/whitelabel/get/",
        publishWhiteLabel: "/api/v1/whitelabel/publish",
        setWhitelabelStatus: "/api/v1/whitelabel/status",
        permissionList: "/api/v1/module/permission",
        getMyAccountStatement: "/api/v1/transaction/me",
        addPaymentMethod: "/api/v1/payment/create",
        getPaymentMethodsList: "/api/v1/payment/get",
        logoutApiURL: "/api/v1/owner/logout",
        assignedModulesList: "/api/v1/module/assign-modules",
        dashboardStats: "/api/v1/owner/stats",
        getMyWalletApiURL: "api/v1/wallet/me",
        qtProviderList: "https://central-provider-socket-beta.project636.online/getQTProvider",
        gapProviderList: "https://central-provider-socket-beta.project636.online/getGAPProvider",
        getAllFeatureList: "/api/v1/features/getall",
        getSelectedProviderList: "/api/v1/features/get-providerIds",
        createFeature: "/api/v1/features/create",
        updateFeature:"/api/v1/features/update",
        getFeatureListWL: "/api/v1/features/me",
        assignFeatureProvider: "/api/v1/features/assign",
        getProfile: "/api/v1/owner/profile/me",
        settlementHistory: "/api/v1/settlement/settlements-history",
        newSettlements: "/api/v1/settlement/new-settlements-list",
        settlementClose: "/api/v1/settlement/create",
      },
      sidebarNav: [
        {
          link: OwnerRoutes.routeDashboard,
          section: "dashboard",
          icon: DashboardRoundedIcon,
          text: OwnerModuleNames.DASHBOARD,
          moduleNameInDB: OwnerModuleNames.DASHBOARD,
          typeToShow: [],
          roleToShow: [UserRole.SOFTWARE, UserRole.CHECKER, UserRole.PILOT], // Not used for owner anymore 
        },
        {
          link: OwnerRoutes.routeAddSubowner,
          section: "addsubowner",
          icon: GroupAddOutlinedIcon, 
          text: OwnerModuleNames.ADD_SUBOWNER,
          moduleNameInDB: OwnerModuleNames.ADD_SUBOWNER,
          typeToShow: [],
          roleToShow: [UserRole.SOFTWARE],
        },
        {
          link: OwnerRoutes.routeWhiteLabelList,
          section: "whitelabellist",
          icon: ListRoundedIcon,   
          text: OwnerModuleNames.WHITELABEL_LIST,
          moduleNameInDB: OwnerModuleNames.WHITELABEL_LIST,
          typeToShow: [],
          roleToShow: [UserRole.SOFTWARE, UserRole.CHECKER, UserRole.PILOT],
        },
        {
          link: OwnerRoutes.routeAdminRole,
          section: "adminrolemanagement",
          icon: ManageAccountsOutlinedIcon, 
          text: "Admin Role",
          moduleNameInDB: OwnerModuleNames.ADMIN_ROLE,  
          typeToShow: [],
          roleToShow: [UserRole.SOFTWARE],
        },
        // {
        //   link: "/featurecontrol",
        //   section: "featurecontrol",
        //   icon: "icon-park-outline:ad-product",
        //   text: "Feature Control",
        //   typeToShow: [],
        //   roleToShow: ['SOFTWARE'],
        // },
        {
          link: OwnerRoutes.routeTransaction,
          section: "transaction",
          icon: RequestPageOutlinedIcon,
          text: OwnerModuleNames.ACCOUNT_STATEMENT,
          moduleNameInDB: OwnerModuleNames.ACCOUNT_STATEMENT,
          typeToShow: [],
          roleToShow: [UserRole.SOFTWARE, UserRole.CHECKER, UserRole.PILOT],
        },
        {
          link: OwnerRoutes.routePaymentTransfer,
          section: "paymenttransfer",
          icon: MonetizationOnOutlinedIcon,
          text: OwnerModuleNames.PAYMENT_METHODS,
          moduleNameInDB: OwnerModuleNames.PAYMENT_METHODS,
          typeToShow: [],
          roleToShow: [UserRole.SOFTWARE, UserRole.PILOT],
        }
      ],
      routesConstatnts: {
        routeUserProfile: OwnerRoutes.routeUserProfile,
        routeAddSubowner: OwnerRoutes.routeAddSubowner,
        routeWhiteLabelList: OwnerRoutes.routeWhiteLabelList,
        routeEditWhiteLabel: OwnerRoutes.routeEditWhiteLabel,
        routeAdminRole: OwnerRoutes.routeAdminRole,
        paymentTransfer: OwnerRoutes.routePaymentTransfer,
        permissionList: OwnerRoutes.permissionList,
        routeTransaction: OwnerRoutes.routeTransaction,
        routeFeatureControl: OwnerRoutes.routeFeatureControl,
        routeSettlement: OwnerRoutes.routeSettlement,
      },
    },
    whitelabel: {
      apiEndpoint: {
        // API Constatnts for WhiteLabel
        loginApiURL: "/api/v1/auth/login",
        getProfile: "/api/v1/auth/profile/me",
        registerApiURL: "/api/v1/auth/register",
        editSubLevelApiURL: "/api/v1/auth/edit",
        getUsers: "/api/v1/auth/users",
        logoutApiURL: "/api/v1/auth/logout",
        refreshToken: "/api/v1/auth/refresh-token",
        changePasswordApiURL: "/api/v1/auth/change-password",
        changePasswordSubLevel: "/api/v1/auth/change-password-for-sub-level",
        getMyWalletApiURL: "/api/v1/wallet/me",
        transactionApiURL: "/api/v1/wallet/transfer",
        getWhiteLabelByIdApiURL: "/api/v1/whitelabel/get/",
        getMyAccountStatement: "/api/v1/transaction/me",
        createPaymentDetailsApiURL: "/api/v1/bank/create-bank-account ",
        getPayementMethodsApiURL: "/api/v1/bank/get-payment-methods",
        changePaymentStatusApiURL: "api/v1/bank/change-payment-methods",
        setWhitelabelStatus: "/api/v1/auth/user/status",
        getBankAccountDetailsApiURL: "/api/v1/bank/get-bank-account",
        changeBankStatusApiURL: "/api/v1/bank/status",
        dashboardStats: "/api/v1/auth/stats",
        createPanelRoleApiURL: "/api/v1/roles/create",
        editPanelRoleApiURL: "/api/v1/roles",
        getPanelRoleApiURL: "/api/v1/roles/get",
        createPanelUserApiURL: "/api/v1/paneluser/create",
        getPanelUserApiURL: "/api/v1/paneluser/get",
        getModulesApiURL: "/api/v1/module/get",
        createModuleApiURL: "/api/v1/module/create",
        getPanelList: "/api/v1/panel/get",
        getAgentListApiURL: "/api/v1/paneluser/agent-list",
        penelUserStatus: "/api/v1/paneluser/status",
        panelUserChangePassword: "/api/v1/paneluser/change-password",
        betLock: "/api/v1/auth/bet-lock",
        fundFreeze: "/api/v1/auth/fund-freeze",
        globalSearch: "/api/v1/auth/global-search",
        getDownlineData: "/api/v1/auth/get-all-downline-users",
        subUserListApiURL: "/api/v1/auth/sub-user-list",
        statsTotalExposure: "/api/v1/stats/get-total-exposure",
        statsClientBalance: "/api/v1/stats/get-client-balance",
        statsTotalusers: "/api/v1/stats/get-total-users",
        statsBetVolume: "/api/v1/stats/get-bet-volume",
        statsTotalBets: "api/v1/stats/get-total-bets",
        statsTopDepositors: "/api/v1/stats/get-top-depositors",
        statsGames: "/api/v1/stats/dashboard-stats-games",
        statsTopWithdrawers: "/api/v1/stats/get-top-withdrawers",
        statsTopLoosers: "api/v1/stats/get-top-losers",
        statsTopWinners: "/api/v1/stats/get-top-winners",
        statsClient: "/api/v1/stats/dashboard-stats-client",
        settlementHistory: "/api/v1/settlement/settlements-history",
        newSettlements: "/api/v1/settlement/new-settlements-list",
        settlementClose: "/api/v1/settlement/create",
        clientPlReport: "/api/v1/report/client-pl",
        sportsWisePlReports: "/api/v1/report/client-pl-sport-wise",
        productWisePlReports: "/api/v1/report/client-pl-product-wise",
        betTickerList: "/api/v1/exchange/admin/agent-running-analysis",
      },
      sidebarNav: [
        {
          link: WhitelabelRoutes.routeDashboard,
          section: "dashboard",
          icon: DashboardRoundedIcon,
          text: WhitelabelModuleNames.DASHBOARD,//Name that can be used to show locally
          moduleNameInDB: WhitelabelModuleNames.DASHBOARD,//Name that is stored in DB
          typeToShow: [WhitelabelType.B2B, WhitelabelType.B2C],
          roleToShow: [UserRole.WHITELABEL, UserRole.ADMIN, UserRole.AGENT, UserRole.SUPER, UserRole.MASTER, UserRole.SUBUSER],
        },
        {
          link: WhitelabelRoutes.routeGlobalSearch,
          section: "globalsearch",  
          icon: SearchIcon,
          text: WhitelabelModuleNames.GLOBAL_SEARCH,//Name that can be used to show locally
          moduleNameInDB: WhitelabelModuleNames.GLOBAL_SEARCH,//Name that is stored in DB
          typeToShow: [WhitelabelType.B2B, WhitelabelType.B2C],
          roleToShow: [UserRole.WHITELABEL, UserRole.ADMIN, UserRole.AGENT, UserRole.SUPER, UserRole.MASTER, UserRole.SUBUSER],
        },
        {
          link: WhitelabelRoutes.routeWhiteLabelUserList,
          section: "whitelabeluserlist",
          icon: ListRoundedIcon,
          text: WhitelabelModuleNames.SUBLEVEL_LISTING,//Name that can be used to show locally
          moduleNameInDB: WhitelabelModuleNames.SUBLEVEL_LISTING,//Name that is stored in DB
          typeToShow: [WhitelabelType.B2B, WhitelabelType.B2C],
          roleToShow: [UserRole.WHITELABEL, UserRole.ADMIN, UserRole.AGENT, UserRole.SUPER, UserRole.MASTER, UserRole.SUBUSER],
        },
        {
          link: WhitelabelRoutes.routeCreateSublevel,
          section: "createsublevel",
          icon: LanRoundedIcon,
          text: WhitelabelModuleNames.SUBLEVEL_CREATION,//Name that can be used to show locally
          moduleNameInDB: WhitelabelModuleNames.SUBLEVEL_CREATION,//Name that is stored in DB
          typeToShow: [WhitelabelType.B2B, WhitelabelType.B2C],
          roleToShow: [UserRole.SUBUSER],
        },
        {
          link: WhitelabelRoutes.routesSubUserRolePanel,
          section: "subuserrolepanel",
          icon: PeopleAltOutlinedIcon, 
          text: WhitelabelModuleNames.SUBUSER_ROLE_PANEL,//Name that can be used to show locally
          moduleNameInDB: WhitelabelModuleNames.SUBUSER_ROLE_PANEL,//Name that is stored in DB
          typeToShow: [WhitelabelType.B2B, WhitelabelType.B2C],
          roleToShow: [UserRole.WHITELABEL, UserRole.ADMIN, UserRole.AGENT, UserRole.SUPER, UserRole.MASTER, UserRole.SUBUSER],
        },
        {
          link: WhitelabelRoutes.routesSubUser,
          section: "subuser",
          icon: LanRoundedIcon, 
          text: WhitelabelModuleNames.SUBUSER_LISTING,//Name that can be used to show locally
          moduleNameInDB: WhitelabelModuleNames.SUBUSER_LISTING,//Name that is stored in DB
          typeToShow: [WhitelabelType.B2B, WhitelabelType.B2C],
          roleToShow: [UserRole.WHITELABEL, UserRole.ADMIN, UserRole.AGENT, UserRole.SUPER, UserRole.MASTER, UserRole.SUBUSER],
        },
        {
          link: WhitelabelRoutes.depositeWithdrawRoleManagement,
          section: "rolemanagment",
          icon: SupervisorAccountOutlinedIcon, 
          text: WhitelabelModuleNames.PANEL_ROLE_MODULE,//Name that can be used to show locally
          moduleNameInDB: WhitelabelModuleNames.PANEL_ROLE_MODULE,//Name that is stored in DB
          typeToShow: [WhitelabelType.B2B, WhitelabelType.B2C],
          roleToShow: [UserRole.WHITELABEL, UserRole.ADMIN],
        },
        {
          link: WhitelabelRoutes.depositeWithdrawPanel,
          section: "paneluser",
          icon: DynamicFeedRoundedIcon,
          text: WhitelabelModuleNames.PANEL_USER_LIST,//Name that can be used to show locally
          moduleNameInDB: WhitelabelModuleNames.PANEL_USER_LIST,//Name that is stored in DB
          typeToShow: [WhitelabelType.B2B, WhitelabelType.B2C],
          roleToShow: [UserRole.WHITELABEL, UserRole.ADMIN],
        },
        {
          link: WhitelabelRoutes.routeTransaction,
          section: "transaction",
          icon: AccountBalanceRoundedIcon, 
          text: WhitelabelModuleNames.TRANSACTION,//Name that can be used to show locally
          moduleNameInDB: WhitelabelModuleNames.TRANSACTION,//Name that is stored in DB
          typeToShow: [WhitelabelType.B2B, WhitelabelType.B2C],
          roleToShow: [UserRole.WHITELABEL, UserRole.ADMIN, UserRole.AGENT, UserRole.SUPER, UserRole.MASTER, UserRole.SUBUSER],
        },
        {
          link: WhitelabelRoutes.routePaymentMethod,
          section: "paymentmethod",
          icon: RequestQuoteOutlinedIcon,
          text: WhitelabelModuleNames.PAYMENT_METHOD,//Name that can be used to show locally
          moduleNameInDB: WhitelabelModuleNames.PAYMENT_METHOD,//Name that is stored in DB
          typeToShow: [WhitelabelType.B2C],
          roleToShow: [UserRole.AGENT],
        },
        {
          link: WhitelabelRoutes.routeBankDetails,
          section: "bankdetails",   
          icon: AccountBalanceRoundedIcon,
          text: WhitelabelModuleNames.BANK_DETAILS,//Name that can be used to show locally
          moduleNameInDB: WhitelabelModuleNames.BANK_DETAILS,//Name that is stored in DB
          typeToShow: [WhitelabelType.B2C],
          roleToShow: [UserRole.AGENT],
        },
        {
          link: WhitelabelRoutes.routesSettlement,
          section: "settlement",   
          icon: AccountBalanceWalletRoundedIcon,
          text: WhitelabelModuleNames.SETTLEMENT,//Name that can be used to show locally
          moduleNameInDB: WhitelabelModuleNames.SETTLEMENT,//Name that is stored in DB
          typeToShow: [WhitelabelType.B2B, WhitelabelType.B2C],
          roleToShow: [UserRole.WHITELABEL, UserRole.ADMIN, UserRole.AGENT, UserRole.SUPER, UserRole.MASTER, UserRole.SUBUSER],
        },
        {
          link: WhitelabelRoutes.routesPLReports,
          section: "plreports",   
          icon: PointOfSaleRoundedIcon,
          text: WhitelabelModuleNames.CLIENT_PL,//Name that can be used to show locally
          moduleNameInDB: WhitelabelModuleNames.CLIENT_PL,//Name that is stored in DB
          typeToShow: [WhitelabelType.B2B, WhitelabelType.B2C],
          roleToShow: [UserRole.WHITELABEL, UserRole.ADMIN, UserRole.AGENT, UserRole.SUPER, UserRole.MASTER, UserRole.SUBUSER],
        },
        {
          link: WhitelabelRoutes.routesBetTicker,
          section: "betticker",   
          icon: PublishedWithChangesRoundedIcon,
          text: WhitelabelModuleNames.BET_TICKER,//Name that can be used to show locally
          moduleNameInDB: WhitelabelModuleNames.BET_TICKER,//Name that is stored in DB
          typeToShow: [WhitelabelType.B2B, WhitelabelType.B2C],
          roleToShow: [UserRole.WHITELABEL, UserRole.ADMIN, UserRole.AGENT, UserRole.SUPER, UserRole.MASTER, UserRole.SUBUSER],
        },
      ],
      routesConstatnts: {
        routeUserProfile: WhitelabelRoutes.routeUserProfile,
        routePaymentMethod: WhitelabelRoutes.routePaymentMethod,
        routeWhiteLabelUserList: WhitelabelRoutes.routeWhiteLabelUserList,
        routeCreateSublevel: WhitelabelRoutes.routeCreateSublevel,
        depositeWithdrawPanel: WhitelabelRoutes.depositeWithdrawPanel,
        depositeWithdrawRoleManagement: WhitelabelRoutes.depositeWithdrawRoleManagement,
        routeTransaction: WhitelabelRoutes.routeTransaction,
        routeBankDetails: WhitelabelRoutes.routeBankDetails,
        routeGlobalSearch: WhitelabelRoutes.routeGlobalSearch,
        routesSubUser: WhitelabelRoutes.routesSubUser,
        routesSubUserRolePanel: WhitelabelRoutes.routesSubUserRolePanel,
        routesSettlement: WhitelabelRoutes.routesSettlement,
        routesPLReports: WhitelabelRoutes.routesPLReports,
        routesBetTicker: WhitelabelRoutes.routesBetTicker,
      },
    }
  }
};
export default config;

