import { useFormik } from "formik";
import { IMintBoxProps, IMintWallet } from "../../../interfaces/interfaceCommon"
import { ownerMintWallet } from "../../../validations/owner/ownerMintWallet";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { Button, Stack } from "@mui/material";
import { InputBox } from "../../common/UI/inputBox/inputBox";
import { getApiUrlEndpointAsPerRole } from "../../../utils/utils";
import postService from "../../../services/post";
import { useContext } from "react";
import BalanceContext from "../../../store/balanceContext";

export const MintBox: React.FunctionComponent<IMintBoxProps> = ({ closeModal, modalId }) => {
    const balanceCtx = useContext(BalanceContext);
    const initialValuesFormik2 = {
        pointsToMint: ""
    }

    const formik2 = useFormik({
        initialValues: initialValuesFormik2,
        validationSchema: ownerMintWallet,
        onSubmit: (values) => {
            let param: IMintWallet = {
                pointsToMint: Number(values.pointsToMint)
            };
            mutationMint.mutate(param);
        },
    });

    const mutationMint = useMutation({
        mutationFn: async (param: IMintWallet) => {
            try {
                const apiEndpoint = getApiUrlEndpointAsPerRole();
                const result = await postService(`${apiEndpoint.ownerMintWalletApiURL}`, param);
                return result;
            } catch (error) {
                throw error;
            }
        },
        onSuccess: () => {
            closeModal(modalId);
            toast.success('Points Mint successful!');
            balanceCtx?.fetchBalance();
            formik2.resetForm();
        },
        onError: (error: any) => {
            toast.error('Points Mint failed: ' + error.response?.data?.message);
        },
    });

    return (
        <form onSubmit={formik2.handleSubmit}>
            <Stack marginBottom={2}>
                <div>
                    <InputBox onChangeHandler={formik2.handleChange} onBlurHandler={formik2.handleBlur} value={formik2.values.pointsToMint} name="pointsToMint" id={"pointsToMint"} label={"Mint points"} variant="outlined" errorMessage={formik2.errors.pointsToMint} isTouched={formik2.touched.pointsToMint} type="number" />
                </div>
            </Stack>
            <div className={`button_Wrapper`}>
                <Button className="customBtn" disabled={!formik2.dirty} type="reset" onClick={() => formik2.resetForm()} size="small" variant="outlined">
                    {"Reset"}
                </Button>
                <Button className="customBtn" disabled={!formik2.isValid || !formik2.dirty} type="submit" size="small" variant="outlined">
                    {"submit"}</Button>
            </div>
        </form>
    )
}