const images = {
  logo: require("../assets/images/project-logo.png"),
  logoMobile: require("../assets/images/project-logo-mobile.png"),
  avt: require("../assets/images/avatar.avif"),
  logKey: require("../assets/images/Reset password-pana.svg"),
  dashboard: require("../assets/images/Revenue-cuate.svg"),
  notFound: require("../assets/images/Oops 404 Error with a broken robot-cuate.svg"),
  fullScreen_icon: require("../assets/images/full-screen.png"),
  rightBottom: require("../assets/images/1-coin.png"),
  leftTop: require("../assets/images/2-coin.png"),
  rightTop: require("../assets/images/3-coin.png"),
  loginRightImage: require("../assets/images/login-right-image.png"),
  OtpVerificationn: require("../assets/images/otp-verification.png"),
  logoBlack: require("../assets/images/logo-black.png"),
};

export default images;